/*
* demo.css
* File include item demo only specific css only
******************************************************************************/
/* @import '~quill/dist/quill.bubble.css'; */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

.light-style .menu .app-brand.demo {
    height: 64px;
  }

  .dark-style .menu .app-brand.demo {
    height: 64px;
  }

  /* .app-brand-logo.demo svg {
    width: 38px;
    height: 20px;
  } */

  .app-brand-text.demo {
    font-size: 1.25rem;
  }

  /* ! For .layout-navbar-fixed added fix padding top tpo .layout-page */
  .layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
    padding-top: 64px !important;
  }

  /* Navbar page z-index issue solution */
  .content-wrapper .navbar {
    z-index: auto;
  }

  /*
  * Content
  ******************************************************************************/

  .demo-blocks > * {
    display: block !important;
  }

  .demo-inline-spacing > * {
    margin: 1rem 0.375rem 0 0 !important;
  }

  /* ? .demo-vertical-spacing class is used to have vertical margins between elements. To remove margin-top from the first-child, use .demo-only-element class with .demo-vertical-spacing class. For example, we have used this class in forms-input-groups.html file. */
  .demo-vertical-spacing > * {
    margin-top: 1.25rem !important;
    margin-bottom: 0 !important;
  }
  .demo-vertical-spacing.demo-only-element > :first-child {
    margin-top: 0 !important;
  }

  .demo-vertical-spacing-lg > * {
    margin-top: 1.875rem !important;
    margin-bottom: 0 !important;
  }
  .demo-vertical-spacing-lg.demo-only-element > :first-child {
    margin-top: 0 !important;
  }

  .demo-vertical-spacing-xl > * {
    margin-top: 5rem !important;
    margin-bottom: 0 !important;
  }
  .demo-vertical-spacing-xl.demo-only-element > :first-child {
    margin-top: 0 !important;
  }

  .rtl-only {
    display: none !important;
    text-align: left !important;
    direction: ltr !important;
  }

  [dir='rtl'] .rtl-only {
    display: block !important;
  }

  /* Dropdown buttons going out of small screens */
  @media (max-width: 576px) {
    #dropdown-variation-demo .btn-group .text-truncate {
      width: 300px;
      position: relative;
    }
    #dropdown-variation-demo .btn-group .text-truncate::after {
      position: absolute;
      top: 45%;
      right: 0.65rem;
    }
  }
  /*
  * Layout demo
  ******************************************************************************/

  .layout-demo-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 1.25rem;
  }
  .layout-demo-placeholder img {
    width: 900px;
  }
  .layout-demo-info {
    text-align: center;
    margin-top: 1.25rem;
  }

  .menu-vertical .menu-item .menu-link svg{
    margin-right: 15px;
  }
  .textDataLabel{
    transform:none
  }
  .advanced-pie-legend .total-label,
  .advanced-pie-legend .total-value {
  display: none;
}

.phoneInput{
  border: none !important;
}
.empty-list{
  display: flex;
    /* align-items: center; */
    justify-content: center;
    /* color: #020202; */
    font-weight: bold;
    font-size: initial;
}
.swal2-popup{
  width: 55rem!important;
  height: 250px;
}

.loader  {
  font-size: 48px;
  color: #FFF;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  position: relative;
}
.loader::after {
  content: '';
  height: 4px;
  width:0%;
  display: block;
  background: #265D91;
  animation: 5s lineGrow linear infinite;
}
@keyframes lineGrow {to{width: 100%;}}

.loading-text{
  top: 50% !important;
}
.p-datepicker{
  z-index: 101 !important;
}
.ngx-spinner-overlay{
  background-color: rgb(0, 0, 0,0.8) !important;
}